// src/App.tsx
import React from 'react';

const App: React.FC = () => {
  return (
    <div className="bg-blue-500 text-white min-h-screen flex items-center justify-center">
      <h1 className="text-4xl font-bold">Yatil</h1>
    </div>
  );
};

export default App;

